<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Factures</h2>
      </div>

      <template v-if="isFetching">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="mb-4">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      <select class="form-control mobile-wid-100" @change="changePage(1)" v-model="perPage">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </div>
                    <div class="ml-2 hidden-md"></div>
                    <div class="form-group mt-1 mobile-wid-100">
                      <input type="text" class="form-control quickfilter-input mobile-wid-100" placeholder="Filtrer par référence, client,.." @change="changePage(1)" v-model="textFilter">
                    </div>
                  </div>
                </div>
                <div>
                  <div class="ml-2 hidden-md"></div>
                  <div class="form-inline">
                    <div class="form-group mt-1 mobile-wid-100">
                      <a class="btn btn-sm btn-link" @click.prevent="reset" >
                        <span class="fa-stack">
                          <fa-icon class="fa-stack-2x text-secondary" :icon="['fas', 'filter']"></fa-icon>
                          <fa-icon class="fa-stack fa-custom-times text-danger" :icon="['fas', 'times']"></fa-icon>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body p-0">
            <div v-if="invoices.length === 0" class="card-body text-center">
              <h4 class="header-title">Vous n'avez aucune facture</h4>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="invoices"
              :default-actions="false"
              :custom-actions="getActions"
              no-top-border
              hover
              :link="(item) => `/invoices/${item.id}`"
              @download="download"
            ></collection-table>
          </div>
        </div>

        <div v-if="paginatedData.pageCount > 1" class="drawer-footer d-flex justify-content-center align-items-center">
          <pagination :current-page="page" state="invoices" :display-per-page="false" :pages="paginatedData.pageCount" :range="2" @change="changePage" />
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import formatsDate from '@/mixins/formatsDate'
import formatsCurrency from '@/mixins/formatsCurrency'
import Pagination from '@/components/common/Pagination'
import download from '@/mixins/download'

export default {
  components: {
    Pagination
  },
  mixins: [formatsDate, formatsCurrency, download],
  computed: {
    getActions () {
      return [
        {
          label: 'Télécharger la facture',
          icon: '',
          event: 'download',
          index: 10
        }
      ]
    },
    isFetching () {
      return this.$store.state.invoices.isFetching
    },
    invoices () {
      return this.paginatedData.data
    },
    paginatedData () {
      return this.$store.state.invoices.paginatedData
    },
    cols () {
      return [
        {
          header: 'Date',
          property: 'date',
          headerClass: 'col-date',
          method: (item) => {
            return this.$longDateTime(item.date)
          }
        },
        {
          header: 'Numéro de facture',
          property: 'reference'
        },
        {
          header: 'Référence de la commande',
          property: 'order.reference',
          method: (item) => {
            return get(item, 'order.reference', '')
          }
        },
        {
          header: 'Client',
          property: 'customer.firstName',
          method: (item) => {
            return `${get(item, 'customer.firstName')} ${get(item, 'customer.lastName')}`
          }
        },
        {
          header: 'Store',
          property: 'store.name',
          method: (item) => {
            return get(item, 'store.name', '')
          }
        },
        {
          header: 'Montant total',
          property: 'total',
          method: (item) => {
            return this.$money(item.total)
          }
        }
      ]
    },
    textFilter: {
      get () {
        return this.$store.state.invoices.textFilter
      },
      set (value) {
        this.$store.commit('invoices/SET_TEXT_FILTER', value)
      }
    },
    perPage: {
      get () {
        return this.$store.state.invoices.perPage
      },
      set (value) {
        this.$store.commit('invoices/SET_PER_PAGE', value)
      }
    },
    page () {
      return this.$store.state.invoices.page
    }
  },
  methods: {
    async download (item) {
      await this.downloadFile(`/v1/admin/invoices/${item.id}/stores/${item.storeId}/pdf`)
    },
    reset () {
      this.textFilter = ''
      this.changePage(1)
      this.$toasted.info('Les filtres sont réinitialisés')
    },
    async changePage (page) {
      await this.fetchInvoices(page)
    },
    async fetchInvoices (page) {
      await this.$store.dispatch('invoices/fetch', { page })
    }
  },
  async created () {
    await this.fetchInvoices()
  }
}
</script>

<style lang="scss" scoped>
.fa-custom-times {
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: 23px;
}
</style>
